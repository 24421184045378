/* Redux */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type NavMenuState = {
  curOpenMenu: string | null;
};

export type OpenMenuPayload = {
  curOpenMenu: string;
};

const initialState: NavMenuState = {
  curOpenMenu: null,
};

export const navMenuSlice = createSlice({
  name: 'navMenu',
  initialState,
  reducers: {
    openMenu: (state, action: PayloadAction<OpenMenuPayload>) => {
      state.curOpenMenu = action.payload.curOpenMenu;
    },
    closeMenu: (state) => {
      state.curOpenMenu = null;
    },
  },
});

export const navMenuAction = navMenuSlice.actions;
