/* React */
import { useCallback } from 'react';
/* Redux */
import { useSelector, useDispatch } from 'react-redux';

/* Store */
import type { RootState } from '../store/store';
import {
  navMenuAction,
  type OpenMenuPayload,
} from '../store/slices/nav-menu-slice';

/* //////////////////////////////////////////////////////////////// */
export default function useStoreNavMenu() {
  const dispatch = useDispatch();

  const { curOpenMenu } = useSelector((state: RootState) => state.navMenu);

  const openMenu = useCallback(
    (payload: OpenMenuPayload) => {
      dispatch(navMenuAction.openMenu(payload));
    },
    [dispatch]
  );

  const closeMenu = useCallback(() => {
    dispatch(navMenuAction.closeMenu());
  }, [dispatch]);

  return { curOpenMenu, openMenu, closeMenu };
}
