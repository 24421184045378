import { createContext, useCallback, useMemo, useState } from 'react';

type LightBoxContextState = {
  openLightBox: boolean;
  curOpenLightBoxSrc: string | null;
  onOpenLightBox: (src: string) => void;
  onCloseLightBox: () => void;
};

const initLightBoxContextState: LightBoxContextState = {
  openLightBox: false,
  curOpenLightBoxSrc: null,
  onOpenLightBox: () => {},
  onCloseLightBox: () => {},
};

const LightBoxContext = createContext<LightBoxContextState>(
  initLightBoxContextState
);

type Props = {
  children: React.ReactNode;
};
function LightBoxContextProvider({ children }: Props) {
  const [openLightBox, setOpenLightBox] = useState<
    LightBoxContextState['openLightBox']
  >(initLightBoxContextState.openLightBox);
  const [curOpenLightBoxSrc, setCurOpenLightBoxSrc] =
    useState<LightBoxContextState['curOpenLightBoxSrc']>(null);

  const onOpenLightBox = useCallback((src: string) => {
    setOpenLightBox(true);
    setCurOpenLightBoxSrc(src);
  }, []);
  const onCloseLightBox = useCallback(() => {
    setOpenLightBox(false);
    setCurOpenLightBoxSrc(null);
  }, []);

  const contextValue = useMemo(
    () => ({
      openLightBox,
      curOpenLightBoxSrc,
      onOpenLightBox,
      onCloseLightBox,
    }),
    [openLightBox, curOpenLightBoxSrc, onOpenLightBox, onCloseLightBox]
  );
  return (
    <LightBoxContext.Provider value={contextValue}>
      {children}
    </LightBoxContext.Provider>
  );
}

export { LightBoxContext, LightBoxContextProvider };
