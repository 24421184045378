/* React */
import { useCallback } from 'react';
/* Redux */
import { useSelector, useDispatch } from 'react-redux';

/* Store */
import type { RootState } from '../store/store';
import { authAction, type LoginTokenPayload } from '../store/slices/auth-slice';

/* //////////////////////////////////////////////////////////////// */
export default function useStoreAuth() {
  const dispatch = useDispatch();

  const { isLogin, loginToken } = useSelector((state: RootState) => state.auth);

  const signIn = useCallback(
    ({
      // eslint-disable-next-line @typescript-eslint/no-shadow
      loginToken,
      loginTokenPayload,
    }: {
      loginToken: string;
      loginTokenPayload: LoginTokenPayload;
    }) => {
      dispatch(
        authAction.signIn({ loginToken, tokenPayload: loginTokenPayload })
      );
    },
    [dispatch]
  );

  const signOut = useCallback(() => {
    dispatch(authAction.signOut());
  }, [dispatch]);

  return { isLogin, loginToken, signIn, signOut };
}
