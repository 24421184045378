/* Redux */
import { useDispatch, useSelector } from 'react-redux';
/* i18n */
import { useTranslation } from 'react-i18next';
/* MUI */
import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  Typography,
} from '@mui/material';
import { orange, red } from '@mui/material/colors';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

/* Store */
import type { RootState } from '../../../store/store';
import { dialogAction, DialogState } from '../../../store/slices/dialog-slice';

/* Styled Components ---------------------------------------------- */
const DialogTitle = styled(MuiDialogTitle)<{ type: DialogState['type'] }>`
  max-width: 500px;
  min-width: 300px;
  font-size: 1.2rem;

  ${(props) =>
    props.type === 'error' &&
    css`
      color: ${red[600]};
    `}
  ${(props) =>
    props.type === 'confirm' &&
    css`
      color: ${orange[700]};
    `}
`;
const ConfirmButton = styled(Button)<{ dialogType: DialogState['type'] }>`
  ${(props) =>
    props.dialogType === 'error' &&
    css`
      background-color: ${red[600]};
      &:hover {
        background-color: ${red[700]};
      }
    `}
  ${(props) =>
    props.dialogType === 'confirm' &&
    css`
      background-color: ${orange[700]};
      &:hover {
        background-color: ${orange[800]};
      }
    `}
`;

/* //////////////////////////////////////////////////////////////// */
export default function Dialog() {
  const dispatch = useDispatch();
  const { isDialogOpen, type, title, message, onConfirmCb } = useSelector(
    (state: RootState) => state.dialog
  );
  const { t } = useTranslation();

  const handleCloseDialog = () => {
    dispatch(dialogAction.closeDialog());
  };
  const handleCancelButtonClick = () => {
    handleCloseDialog();
  };
  const handleConfirmButtonClick = () => {
    onConfirmCb();
    handleCloseDialog();
  };

  /* JSX ---------------------------------------------------------- */
  return (
    <MuiDialog open={isDialogOpen} onClose={handleCloseDialog}>
      <DialogTitle type={type}>{t(title)}</DialogTitle>

      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>

      <DialogActions>
        {type !== 'error' ? (
          <Button onClick={handleCancelButtonClick} color="inherit">
            {t('dialog__button--cancel')}
          </Button>
        ) : null}

        <ConfirmButton dialogType={type} onClick={handleConfirmButtonClick}>
          {t('dialog__button--okay')}
        </ConfirmButton>
      </DialogActions>
    </MuiDialog>
  );
}
