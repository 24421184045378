/* React */
import { useEffect } from 'react';
/* Socket */
import io from 'socket.io-client';

/* Hooks */
import useStoreSocket from './useStoreSocket';

/* //////////////////////////////////////////////////////////////// */
export default function useSocket() {
  const { connect } = useStoreSocket();

  useEffect(() => {
    const newSocket = io({
      path: '/api/socket/io',
      extraHeaders: {
        Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      },
    });

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any -- [FIXME] follow follow https://github.com/AntonioErdeljac/next13-discord-clone/blob/master/pages/api/socket/io.ts */
    connect({ socket: newSocket as any });

    newSocket.on('connect', () => {
      // console.log('connect to socket');
    });

    return () => {
      newSocket?.disconnect();
    };
  }, [connect]);
}
