/* React */
import { useCallback, useMemo } from 'react';
/* Redux */
import { useDispatch } from 'react-redux';

/* Store */
import { dialogAction, OpenDialogPayload } from '../store/slices/dialog-slice';
import { Prettify } from '../types/helper';

type CallbackProps = Prettify<
  Omit<Omit<OpenDialogPayload, 'type'>, 'title'> & {
    title?: string;
  }
>;

/* //////////////////////////////////////////////////////////////// */
export default function useDialog() {
  const dispatch = useDispatch();

  const error = useCallback(
    (payload: CallbackProps) => {
      dispatch(
        dialogAction.openDialog({
          type: 'error',
          title: 'dialog__title--error',
          ...payload,
        })
      );
    },
    [dispatch]
  );

  const confirm = useCallback(
    (payload: CallbackProps) => {
      dispatch(
        dialogAction.openDialog({
          type: 'confirm',
          title: 'dialog__title--confirm',
          ...payload,
        })
      );
    },
    [dispatch]
  );

  return useMemo(() => ({ error, confirm }), [error, confirm]);
}
