/* React */
import { useEffect } from 'react';
/* i18n */
import { useTranslation } from 'react-i18next';

/* Hooks */
import useDialog from '../hooks/useDialog';
/* Modals */
import { httpErrorSchema } from '../models/http-error';

/* //////////////////////////////////////////////////////////////// */
type Props = {
  error: unknown;
  errorMessagePrefix?: string;
  onConfirmErrorCb?: () => void;
};

export default function useHandleHttpError({
  error,
  errorMessagePrefix,
  onConfirmErrorCb,
}: Props) {
  const { t } = useTranslation();

  const dialog = useDialog();

  useEffect(() => {
    if (!error) return;

    const parsedHttpError = httpErrorSchema.safeParse(error);

    if (parsedHttpError.success) {
      dialog.error({
        message: errorMessagePrefix
          ? errorMessagePrefix + parsedHttpError.data.message
          : parsedHttpError.data.message,
        onConfirmCb: onConfirmErrorCb,
      });
    } else {
      dialog.error({
        message: t('general__error-messages__unknown'),
        onConfirmCb: onConfirmErrorCb,
      });
    }
  }, [t, dialog, error, errorMessagePrefix, onConfirmErrorCb]);
}
