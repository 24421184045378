/* React */
import React from 'react';

/* //////////////////////////////////////////////////////////////// */
export default function useLogin(event: React.MouseEvent | undefined): void {
    // States or Parameters
    const oryLoginUrl = 'https://login.lightup.tw';
    const customApiUrl = 'https://login.ms.lightup.tw/user-client';

    // Get new token
    const getLoginToken = async() => {
        try{
            const url = `${customApiUrl}/oauth/token`;
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            const responseJson = await response.json();
            const accessToken = responseJson?.access_token ?? "";
            const refreshToken = responseJson?.refresh_token ?? "";
            const expiry = responseJson?.expires_at ?? "";
            window.sessionStorage.setItem('accesstoken', accessToken);
            window.sessionStorage.setItem('refresh_token', refreshToken);
            window.sessionStorage.setItem('expiry', expiry);
            return true; 
        } catch(error) {
            console.log(`getLoginToken error: ${error}`);
            throw error;
        }
    }

    // Login
    const login = async(event: React.MouseEvent | undefined) => {
        event?.preventDefault();
        const returnToUrl = encodeURI(`https://${window.location.host}`); // 登入後要返回的頁面
        const loginUrl = `${oryLoginUrl}/oauth/?return_to=${returnToUrl}`; // OAuth登入頁面
        const setStorage = async() => {
            window.sessionStorage.removeItem('logout');
            window.sessionStorage.setItem('login', 'true');
        }
        const promiseFunc = () => {
            return new Promise(async(resolve) => {
                await getLoginToken();
                await setStorage();
                resolve(true);
            })
        }
        await promiseFunc().then((bool) => {
            if (bool) {
                window.location.href = loginUrl;
            }
        });
    }
    login(event);
}