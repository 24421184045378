/* Redux */
import { configureStore } from '@reduxjs/toolkit';

/* Slices */
import { authSlice } from './slices/auth-slice';
import { dialogSlice } from './slices/dialog-slice';
import { navMenuSlice } from './slices/nav-menu-slice';
import { socketSlice } from './slices/socket-slice';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  reducer: {
    auth: authSlice.reducer,
    dialog: dialogSlice.reducer,
    navMenu: navMenuSlice.reducer,
    socket: socketSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
