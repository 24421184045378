/* Date-fns */
import { format } from 'date-fns';
/* MUI */
import { Avatar, Box } from '@mui/material';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

/* Models */
import type { TMessageItem } from '../../../../models/chatroom/messages';
/* Components */
import MessageContent from './MessageContent';

/* Styled Components ---------------------------------------------- */
const Container = styled.div<{ position: 'left' | 'right' }>`
  display: flex;
  gap: 0.5rem;
  ${(props) =>
    props.position === 'left'
      ? css`
          justify-content: start;
        `
      : css`
          justify-content: end;
        `}

  margin: 0.5rem;
`;
const MessageBubble = styled.div<{
  channelType: TMessageItem['channelType'];
}>`
  display: inline-block;

  width: 100%;
  max-width: 22.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  overflow: hidden;

  ${(props) => {
    if (props.channelType === 'CHAT')
      return css`
        background-color: white;
      `;
    if (props.channelType === 'LINE')
      return css`
        background-color: #06c75520;
      `;
    return css`
      background-color: white;
    `;
  }}
`;
const StyledMessageSendAt = styled.p`
  font-size: 13px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

/* //////////////////////////////////////////////////////////////// */
type Props = {
  message: TMessageItem;
};

export default function MessageListItem({ message }: Props) {
  /* JSX ---------------------------------------------------------- */
  return (
    <Container position={message.userType === 'User' ? 'left' : 'right'}>
      {message.userType === 'User' ? <Avatar /> : null}

      <Box display="flex" flexDirection="column" alignItems="end" gap="0.25rem">
        <MessageBubble channelType={message.channelType}>
          <MessageContent message={message} />
        </MessageBubble>

        <StyledMessageSendAt>
          {format(message.createdAt, 'HH:mm')}
        </StyledMessageSendAt>
      </Box>
    </Container>
  );
}
