/* React */
import React, { useCallback, useRef, useState } from 'react';
/* MUI */
import { Box, Button } from '@mui/material';
import styled from '@emotion/styled';
/* i18n */
import { useTranslation } from 'react-i18next';

/* Const */
import { CHANNEL_ID_KEY_MAP } from '../../../../const/channel-id-key-map';
/* Hooks */
import useStoreSocket from '../../../../hooks/useStoreSocket';
import useSendMessage from '../../../../features/chatroom/hooks/useSendMessage';
/* Models */
import type { TMessageItem } from '../../../../models/chatroom/messages';
import type { TRoom } from '../../../../models/chatroom/room';
/* Components */
import ChannelSelector from './ChannelSelector';
import FileUploader from './FileUploader';

/* Styled Components ---------------------------------------------- */
const MessageContainer = styled.div`
  padding: 0.5rem;
  border-top: ${(props) => props.theme.chatroom.border};
`;
const MessageBox = styled.form`
  padding: 0.5rem;
  background-color: ${(props) => props.theme.palette.background.paper};
`;
const TextField = styled('textarea')`
  width: 100%;
  border: none;
  outline: none;
  resize: none;
  font-family: inherit;
`;

/* //////////////////////////////////////////////////////////////// */
type Props = {
  room: TRoom;
};

export default function MessageInput({ room }: Props) {
  const [channelType, setChannelType] =
    useState<TMessageItem['channelType']>('CHAT');
  const [inputText, setInputText] = useState<string>('');

  // for press enter to submit
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  const { t } = useTranslation();

  const { socket } = useStoreSocket();
  const { onSendMessage } = useSendMessage();

  // Send message to microservice
  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (inputText.trim().length === 0 || !socket) return;
    const message: TMessageItem = {
      userType: 'CS', // get role from redux auth slice or set this key on server side
      sendTo: room[CHANNEL_ID_KEY_MAP[channelType] as 'chatId' | 'lineId']!,
      senderId: '', // get userId from redux auth slice or set this key on server side
      createdAt: new Date().getTime(),
      channelType,
      data: {
        type: 'text',
        text: inputText,
      },
    };
    onSendMessage({ message, channelType });
    setInputText('');
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputText(e.target.value);
  };
  const handleTextAreaKeyPress = (
    e: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    // press `Enter` to submit
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      submitButtonRef.current?.click(); // click submit button
    }
  };
  const handleChannelTypeChange = useCallback(
    (selectedChannelType: TMessageItem['channelType']) => {
      setChannelType(selectedChannelType);
    },
    []
  );

  /* JSX ---------------------------------------------------------- */
  return (
    <MessageContainer>
      <MessageBox onSubmit={handleFormSubmit}>
        <TextField
          value={inputText}
          onChange={handleInputChange}
          onKeyDown={handleTextAreaKeyPress}
          rows={3}
          spellCheck={false}
          placeholder={t('chatroom__message-input__placeholder')}
        />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <FileUploader channelType={channelType} room={room} />

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="0.5rem"
          >
            <ChannelSelector
              room={room}
              channelType={channelType}
              onChannelTypeChange={handleChannelTypeChange}
            />
            <Button ref={submitButtonRef} type="submit" size="small">
              <span>{t('chatroom__message-input__submit-text')}</span>
            </Button>
          </Box>
        </Box>
      </MessageBox>
    </MessageContainer>
  );
}
