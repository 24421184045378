/* React */
import { useCallback } from 'react';
/* Redux */
import { useSelector, useDispatch } from 'react-redux';

/* Store */
import type { RootState } from '../store/store';
import { socketAction, type ConnectPayload } from '../store/slices/socket-slice';

/* //////////////////////////////////////////////////////////////// */
export default function useStoreSocket() {
  const dispatch = useDispatch();

  const { socket } = useSelector((state: RootState) => state.socket);

  const connect = useCallback(
    (param: ConnectPayload) => {
      dispatch(socketAction.connect(param));
    },
    [dispatch]
  );

  const disconnect = useCallback(() => {
    dispatch(socketAction.disconnect());
  }, [dispatch]);

  return { socket, connect, disconnect };
}
