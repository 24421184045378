/* Models */
import type { TMessageItem } from '../../../models/chatroom/messages';
/* Types */
import type { TMessageTypeAndData } from '../../../features/chatroom/types/message-content';

/* //////////////////////////////////////////////////////////////// */
export default function useDetectMessageType(
  message: TMessageItem
): TMessageTypeAndData {
  let result: TMessageTypeAndData = {
    type: 'text',
    messageData: {
      text: '[UNSUPPORTED CONTENT]',
    },
  };

  /* LINE --------------------------------------------------------- */
  if (message.channelType === 'LINE') {
    if (message.data.type === 'text')
      result = {
        type: 'text',
        messageData: {
          text: message.data.text,
        },
      };
    else if (message.data.type === 'image')
      result = {
        type: 'image',
        messageData: {
          src: message.data.originalContentUrl,
        },
      };
    else if (message.data.type === 'video')
      result = {
        type: 'video',
        messageData: {
          src: message.data.originalContentUrl,
          previewImageUrl: message.data.previewImageUrl,
        },
      };
    else if (message.data.type === 'audio')
      result = {
        type: 'audio',
        messageData: {
          src: message.data.originalContentUrl,
        },
      };
  }
  /* CHAT --------------------------------------------------------- */
  if (message.channelType === 'CHAT') {
    if (message.data.type === 'text')
      result = {
        type: 'text',
        messageData: {
          text: message.data.text,
        },
      };
    else if (message.data.type === 'image')
      result = {
        type: 'image',
        messageData: {
          src: message.data.originalContentUrl,
        },
      };
    else if (message.data.type === 'video')
      result = {
        type: 'video',
        messageData: {
          src: message.data.originalContentUrl,
          previewImageUrl: message.data.previewImageUrl,
        },
      };
    else if (message.data.type === 'audio')
      result = {
        type: 'audio',
        messageData: {
          src: message.data.originalContentUrl,
        },
      };
    else if (message.data.type === 'file') {
      result = {
        type: 'file',
        messageData: {
          originalContentUrl: message.data.originalContentUrl,
          originalFileName: message.data.originalFileName,
          fileType: message.data.fileType,
          fileSize: message.data.fileSize,
        },
      };
    }
  }

  return result;
}
