/* MUI */
import styled from '@emotion/styled';

/* Hooks */
import useStoreLightBox from '../../../../features/chatroom/hooks/useStoreLightBox';
/* Types */
import type { TMessageDataVideo } from '../../../../features/chatroom/types/message-content';

/* Styled Components ---------------------------------------------- */
const Figure = styled('figure')`
  position: relative;
  width: 18rem;
  cursor: pointer;
`;
const Image = styled('img')`
  width: 100%;
  object-fit: cover;
`;
const PlayIcon = styled('img')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 100px;
  width: 100px;
`;

/* //////////////////////////////////////////////////////////////// */
type Props = {
  messageData: TMessageDataVideo;
};

export default function MessageContentVideo({ messageData }: Props) {
  const { onOpenLightBox } = useStoreLightBox();

  const handleOpenLightBox = () => {
    onOpenLightBox(messageData.src);
  };

  /* JSX ---------------------------------------------------------- */
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    // <video controls width={320} poster={messageData.previewImageUrl}>
    //   <source src={messageData.src} type="video/mp4" />
    // </video>

    <Figure onClick={handleOpenLightBox}>
      <Image
        src={messageData.previewImageUrl}
        alt={messageData.previewImageUrl}
        loading="lazy"
      />
      <PlayIcon src="/img/play-icon.png" />
    </Figure>
  );
}
