import i18next, { type Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

import { translation_en } from './resources/en';
import { translation_zh_tw } from './resources/zh_tw';
import { translation_zh_cn } from './resources/zh_cn';

const resources: Resource = {
  en: {
    translation: translation_en,
  },
  zh_tw: {
    translation: translation_zh_tw,
  },
  zh_cn: {
    translation: translation_zh_cn,
  },
};

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    lng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
