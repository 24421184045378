import type { Shadows } from '@mui/material';

const shadow = `0px 1px 2px 0px rgba(0, 0, 0, 0.05);`;

const shadows: Shadows = [
  'none',
  shadow,
  shadow,
  shadow,
  shadow,
  shadow,
  shadow,
  shadow,
  shadow,
  shadow,
  shadow,
  shadow,
  shadow,
  shadow,
  shadow,
  shadow,
  shadow,
  shadow,
  shadow,
  shadow,
  shadow,
  shadow,
  shadow,
  shadow,
  shadow,
];

export default shadows;
