/* Light Box */
import type { Slide } from 'yet-another-react-lightbox';

/* Models */
import type { TMessageItem } from '../models/chatroom/messages';

export const messagesToLightBoxSlides = (messages: TMessageItem[]): Slide[] => {
  const slides: Slide[] = [];

  messages.forEach((message) => {
    if (message.channelType === 'CHAT' || message.channelType === 'LINE') {
      if (message.data.type === 'image')
        slides.push({
          type: 'image',
          src: message.data.originalContentUrl,
        });
      if (message.data.type === 'video')
        slides.push({
          type: 'video',
          poster: message.data.previewImageUrl,
          sources: [
            {
              src: message.data.originalContentUrl,
              type: 'video/mp4', // [TODO] use actual file type
            },
          ],
          autoPlay: true,
        });
    }
  });

  return slides;
};

// Example slides data
// [
//   {
//     type: 'image',
//     src: 'https://minio.dev.lightup.tw/chat-messages/message-6545d569-0ed2-4909-bcc9-82c8308f50d15eb13608-9ef2-4e14-927c-73e86d2bc3af.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=aYhuX59nCrg8fwcE%2F20231102%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231102T011959Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=fdb3d209e4384c064d2868d068dbedd50630f14bf393f9d588b88f55a9b44fd3',
//   },
//   {
//     type: 'video',
//     poster:
//       'https://en.sailor.co.jp/wp-content/uploads/2021/02/eyechatch_thenibs.jpg',
//     sources: [
//       {
//         src: 'https://minio.dev.lightup.tw/chat-messages/message-31cf68e9-70d5-46ee-ab15-944cf5609520bed5bfc3-7241-4ae9-bf52-d75a241895d2.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=aYhuX59nCrg8fwcE%2F20231102%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231102T015419Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=e371b8d777a81ddc23b761e40d8bb8eb4668fb4695675e9be0d5a679c2db7e1d',
//         type: 'video/mp4',
//       },
//     ],
//   },
// ];
