/* eslint-disable @typescript-eslint/naming-convention -- use BEM notation here */

export const translation_en = {
  /* General ------------------------------------------------------ */
  'general__error-messages__unknown':
    'An error occurred, please try again later.',

  /* Dialog ------------------------------------------------------- */
  'dialog__title--error': 'Error',
  'dialog__title--confirm': 'Confirm',
  'dialog__button--okay': 'Ok',
  'dialog__button--cancel': 'Cancel',

  /* Navbar ------------------------------------------------------- */
  'navbar__language-dropdown__tooltip': 'Language',

  'navbar__user-dropdown__tooltip': 'Account',
  'navbar__user-dropdown__menu__logout': 'Logout',
  'navbar__user-dropdown__dialog__message': 'Do you want to log out?',

  /* Sidebar ------------------------------------------------------ */
  sidebar__chatroom: 'Chatroom',

  sidebar__line: 'Line',
  sidebar__line__message: 'Message',
  sidebar__line__message__statistics: 'Statistics',
  'sidebar__line__message__message-template': 'Message templates',
  'sidebar__line__message__send-message': 'Send message',
  'sidebar__line__message__message-list': 'Message list',
  sidebar__line__message__schedule: 'Schedule',
  'sidebar__line__rich-menu': 'Rich menu',

  'sidebar__push-notification': 'Push Notification',
  'sidebar__push-notification__push': 'Push',

  sidebar__tag: 'Tag',

  /* Chatroom ----------------------------------------------------- */
  'chatroom__message-header__i18n-button-text': 'Language',
  'chatroom__message-input__submit-text': 'send',
  'chatroom__message-input__placeholder':
    'Press `Enter` to send, `Shift` + `Enter` to start a new line.',
  'chatroom__file-uploader__tooltip': 'upload file',

  /* Chatroom user detail ----------------------------------------- */
  'chatroom__user-detail__heading': 'Detail Info',

  'chatroom__user-detail__tag__heading': 'Tag',
  'chatroom__user-detail__tag-modal__selected-tags__heading': 'Selected tags',
  'chatroom__user-detail__tag-modal__selected-tags__empty': 'No selected tags',
  'chatroom__user-detail__tag-modal__selectable-tags__heading':
    'Selectable tags',
  'chatroom__user-detail__tag-modal__selectable-tags__empty':
    'No Selectable tags',

  /* LINE General ------------------------------------------------- */
  'line__general__send-method--broadcast': 'Broadcast',
  'line__general__send-method--multicast': 'Multicast',

  'line__general__send-time--immediately': 'Immediately',
  'line__general__send-time--reservation': 'Reservation',

  'line__general__message-type--text': 'Text message',
  'line__general__message-type--flex': 'Flex message',

  'line__general__field-name--estimated-sending-time': 'Estimated sending time',
  'line__general__field-name--send-method': 'Send method',
  'line__general__field-name--title': 'Title (internal use)',
  'line__general__field-name--message-type': 'Message type',
  'line__general__field-name--create-user-name': 'Creator',
  'line__general__field-name--created-at': 'Created at',

  /* LINE Template ------------------------------------------------ */
  line__template__heading: 'Single Message Template',

  'line__template__button--create': 'Create template',
  line__template__filter__search__label: 'Title (internal use)',
  line__template__filter__search__placeholder:
    'Search title (maximum of 20 characters)',

  'line__template__table__confirm-modal--delete':
    'Delete this message template?',

  'line__template__form__heading--create': 'Create template',
  'line__template__form__heading--edit': 'Edit template',
  'line__template__form__submit-button--create': 'Create',
  'line__template__form__submit-button--edit': 'Edit',
  'line__template__form__error-message__title--empty': 'Title cannot be empty',

  'line__template__toast--success__create-template':
    'Template created successfully',
  'line__template__toast--success__edit-template':
    'Template edited successfully',
  'line__template__toast--success__delete-template':
    'Template deleted successfully',

  /* LINE Send message -------------------------------------------- */
  'line__send-message__title__description': '[TODO] Translation',
  'line__send-message__title--create': 'Create message',
  'line__send-message__title--edit': 'Edit message',

  'line__send-message__form__label__template-selector': 'Select template',
  'line__send-message__form__submit-button-text--create': 'Create message',
  'line__send-message__form__submit-button-text--edit': 'Edit message',
  'line__send-message__form__add-message-button-text': 'Add a single message',

  'line__send-message__form__error-messages--purpose': 'Title cannot be empty',
  'line__send-message__form__error-messages--date':
    'Please select a reservation date',
  'line__send-message__form__error-messages--text__text':
    'Message cannot be empty',
  'line__send-message__form__error-messages--flex-message__alt-text':
    'Alt text cannot be empty',
  'line__send-message__form__error-messages--flex-message__contents':
    'Please enter a valid flex message',
  'line__send-message__dialog--error__get-message': 'Message not found',

  'line__send-message__toast--success__create-message':
    'Message created successfully',
  'line__send-message__toast--success__edit-message':
    'Message edited successfully',

  /* LINE Messages ------------------------------------------------ */
  line__messages__heading: 'Message list',
  'line__messages__filter--search__label': 'Title (internal use)',
  'line__messages__filter--search__placeholder':
    'Search title (maximum of 20 characters)',
  'line__messages__filter--send-method__label': 'Send method',
  'line__messages__send-method-selector--all': 'All',

  'line__messages__table__header--approving-status': 'Approving state',
  'line__messages__table__header--sent-at': 'Sent At',

  'line__messages__table__estimated-sending-time--send-immediately':
    'Send immediately',
  'line__messages__table__approving-status--wait': 'Wait',
  'line__messages__table__approving-status--agree': 'Agree',
  'line__messages__table__approving-status--reject': 'Reject',
  'line__messages__table__sending-status--cancel': 'Cancel',
  'line__messages__table__sending-status--sent': 'Sent',
  'line__messages__table__sending-status--wait': 'Wait',

  /* LINE Message detail modal content ---------------------------- */
  'line__message-detail__edit-message-button': 'Edit message',
  'line__message-detail__form--confirm__title': 'Confirm',
  'line__message-detail__form--confirm__placeholder':
    'Feedback message (maximum of 200 characters)',
  'line__message-detail__form--confirm__dialog--agree__message':
    'Agree to send the message?',
  'line__message-detail__form--confirm__dialog--reject__message':
    'Decline to send message?',
  'line__message-detail__form--confirm__button--agree': 'Agree',
  'line__message-detail__form--confirm__button--reject': 'Decline',

  'line__message-detail__form--cancel__title': 'Cancel',
  'line__message-detail__form--cancel__placeholder':
    'Feedback message (maximum of 200 characters)',
  'line__message-detail__form--cancel__dialog__message':
    'Cancel sending message?',
  'line__message-detail__form--cancel__button': 'Cancel',

  'line__message-detail__table__header--approving-status': 'Approving state',
  'line__message-detail__table__header--sent-at': 'Sent At',
  'line__message-detail__table__header--confirmed-at': 'Confirmed at',
  'line__message-detail__table__header--confirmed-user-name': 'Confirmed by',
  'line__message-detail__table__header--confirmed-comment': 'Confirmed comment',

  'line__message-detail__table__content__estimated-sending-time--immediately':
    'Send immediately',

  'line__message-detail__table__messages__label': 'message',

  'line__message-detail__toast--success__cancel-send':
    'Cancel sending message successfully',
  'line__message-detail__toast--success__confirm-send': 'Confirmed message',

  /* LINE Messages schedule --------------------------------------- */
  'line__message-schedule__calendar__button-text--today': 'Today',
  'line__message-schedule__calendar__button-text--month': 'Month',
  'line__message-schedule__calendar__button-text--week': 'Week',
  'line__message-schedule__calendar__button-text--day': 'day',

  /* Push Notification General ------------------------------------ */
  'push-notification__general__field-name__title': 'Title',
  'push-notification__general__field-name__body': 'Content',

  /* Push Notification Push --------------------------------------- */
  'push-notification__push__heading': 'Push Notification',
  'push-notification__push__from__error-messages__title--no-empty':
    'Title cannot be empty',
  'push-notification__push__from__error-messages__body--no-empty':
    'Content cannot be empty',

  'push-notification__push__toast--success__push-notification':
    'Push notification successfully',

  /* Tag General -------------------------------------------------- */
  'tag__general__field-name__tag-type': 'Tag type',
  'tag__general__field-name__tag-name': 'Tag name',
  'tag__general__field-name__priority': 'Priority',
  'tag__general__field-name__description': 'Description',
  'tag__general__field-name__quantity-tagged': 'Quantity tagged',

  /* Tag table ---------------------------------------------------- */
  tag__table__heading: 'Tag',
  'tag__table__button--create': 'Create tag',
  tag__table__filter__search__label:
    'Tag name / Description / Created user name',
  tag__table__filter__search__placeholder:
    'Search Tags (maximum of 20 characters)',

  /* Tag form ----------------------------------------------------- */
  'tag__form__heading--create': 'Create Tag',
  'tag__form__heading--edit': 'Edit Tag',
  'tag__form__submit-button--create': 'Create tag',
  'tag__form__submit-button--edit': 'Edit tag',

  'tag__confirm-modal--delete-tag': 'Delete this tag?',

  'tag__toast--success__create-tag': 'Create tag successfully',
  'tag__toast--success__edit-tag': 'Edit tag successfully',
  'tag__toast--success__delete-tag': 'Delete tag successfully',
};

export type Translation = typeof translation_en;
