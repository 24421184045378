/* React */
import { useEffect, useState } from 'react';
/* i18n */
import { useTranslation } from 'react-i18next';
/* JWT */
import { decode } from 'jsonwebtoken';

/* Hooks */
import useAuthentication from '../hooks/useAuthentication';
/* Store */
import { loginTokenPayloadSchema } from '../store/slices/auth-slice';

/* //////////////////////////////////////////////////////////////// */
export default function useAppSetting() {
  const [appSet, setAppSet] = useState<boolean>(false);

  const { signIn } = useAuthentication();

  const { i18n } = useTranslation();

  useEffect(() => {
    // (1) Log user in is has token and token is valid
    const loginToken = localStorage.getItem('loginToken');

    if (!loginToken) return setAppSet(true);

    const parsedLoginToken = loginTokenPayloadSchema.safeParse(
      decode(loginToken)
    );

    if (!parsedLoginToken.success) return setAppSet(true);

    signIn({ loginToken, loginTokenPayload: parsedLoginToken.data });

    // (2) Get locale from local storage
    const langCode = localStorage.getItem('locale');
    i18n.changeLanguage(langCode || 'en');

    return setAppSet(true);
  }, [signIn, i18n]);

  return { appSet };
}
