/* React */
import { useRef } from 'react';
/* i18n */
import { useTranslation } from 'react-i18next';
/* MUI */
import { Box, IconButton, Tooltip } from '@mui/material';
import { FileUpload } from '@mui/icons-material';

/* Hooks */
import useDialog from '../../../../hooks/useDialog';
import useUploadFile from '../../../../features/chatroom/hooks/useUploadFile';
/* Models */
import type { TMessageItem } from '../../../../models/chatroom/messages';
import type { TRoom } from '../../../../models/chatroom/room';

const acceptFileTypeMap: Record<TMessageItem['channelType'], Array<string>> = {
  CHAT: ['*/*'],
  LINE: ['image/png', 'image/jpeg', 'video/mp4', 'audio/x-m4a'],
};

/* //////////////////////////////////////////////////////////////// */
type Props = {
  channelType: TMessageItem['channelType'];
  room: TRoom;
};

export default function FileUploader({ channelType, room }: Props) {
  const { t } = useTranslation();

  const dialog = useDialog();
  const { onUploadFile } = useUploadFile();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) return;
    if (inputRef.current?.value) inputRef.current.value = ''; // clear file input

    // Validate filetype in frontend
    if (
      channelType !== 'CHAT' &&
      !acceptFileTypeMap[channelType].includes(file.type)
    ) {
      dialog.error({ message: 'Unsupported content!' });
      return;
    }

    onUploadFile({
      file,
      channelType,
      roomId: room.roomId,
      userType: 'CS',
      userId: 'user-id', // [TODO] use actual userId
    });
  };

  /* JSX ---------------------------------------------------------- */
  return (
    <Box>
      <Tooltip title={t('chatroom__file-uploader__tooltip')}>
        <IconButton
          component="label"
          // htmlFor="chatroom__message-input__file-uploader" // [BUG] will  change channelType
        >
          <FileUpload />
          <input
            ref={inputRef}
            type="file"
            style={{ display: 'none' }}
            accept={acceptFileTypeMap[channelType].join(', ')}
            onChange={handleFileSelect}
            // id="chatroom__message-input__file-uploader" // [BUG]
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
