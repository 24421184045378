import type { TMessageItem } from '../../../models/chatroom/messages';

export const sendMessage = async ({
  message,
  channelType,
}: {
  message: TMessageItem;
  channelType: TMessageItem['channelType'];
}) => {
  const res = await fetch(`/api/message/${channelType.toLowerCase()}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(message),
  });

  const body = await res.json();

  if (!res.ok) throw body;

  return body;
};
