/* React */
import React, { useEffect, useRef, useState } from 'react';
/* Date fns */
import { format } from 'date-fns';
/* MUI */
import { Box } from '@mui/material';

/* Hooks */
import useStoreSocket from '../../../../hooks/useStoreSocket';
/* Models */
import type { TMessageItem } from '../../../../models/chatroom/messages';
/* Store */
import { LightBoxContextProvider } from '../../../../features/chatroom/store/LightBoxContext';
/* Components */
import DateSeparator from './DateSeparator';
import MessageListItem from './MessageListItem';
import YewLightBox from './YewLightBox';

/* //////////////////////////////////////////////////////////////// */
type Props = {
  currentRoomId: string;
};

export default function MessageList({ currentRoomId }: Props) {
  const [messages, setMessages] = useState<TMessageItem[]>([]);

  const messageListContainerRef = useRef<HTMLDivElement | null>(null);

  const { socket } = useStoreSocket();

  // init Socket.io
  useEffect(() => {
    if (!socket) return;

    const handleInitMessages = ({
      messages: receivedMessages,
    }: {
      messages: TMessageItem[];
    }) => {
      setMessages(receivedMessages);
    };
    const handleNewMessage = ({ message }: { message: TMessageItem }) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    };

    socket.emit('req-messages', { roomId: currentRoomId, page: 1 });
    socket.on('res-messages', handleInitMessages);
    socket.on('new-message', handleNewMessage);

    // eslint-disable-next-line consistent-return
    return () => {
      socket.off('res-messages', handleInitMessages);
      socket.off('new-message', handleNewMessage);
    };
  }, [socket, currentRoomId]);

  // Scroll to bottom when message change // [TODO] [FIXME] flash when enter room
  useEffect(() => {
    if (!messageListContainerRef.current) return;
    messageListContainerRef.current.scrollTop =
      messageListContainerRef.current.scrollHeight;
  }, [messages]);

  /* JSX ---------------------------------------------------------- */
  return (
    <LightBoxContextProvider>
      <YewLightBox messages={messages} />

      <Box
        ref={messageListContainerRef}
        padding="0.5rem"
        sx={{ overflowY: 'auto' }}
      >
        {/* Display DateSeparator when the creation date of the message is different from the previous message's creation date */}
        {messages.map((message, index) =>
          !messages[index - 1] ||
          format(message.createdAt, 'yyyy/MM/dd') !==
            format(messages[index - 1].createdAt, 'yyyy/MM/dd') ? (
            <React.Fragment key={message.createdAt}>
              <DateSeparator dateTime={message.createdAt} />
              <MessageListItem message={message} />
            </React.Fragment>
          ) : (
            <MessageListItem key={message.createdAt} message={message} />
          )
        )}
      </Box>
    </LightBoxContextProvider>
  );
}
