/* React */
import { useCallback } from 'react';

/* Hooks */
import useStoreAuth from '../hooks/useStoreAuth';
/* Store */
import type { LoginTokenPayload } from '../store/slices/auth-slice';

/* //////////////////////////////////////////////////////////////// */
export default function useAuthentication() {
  const { signIn: signInAction, signOut: signOutAction } = useStoreAuth();

  const signIn = useCallback(
    ({
      loginToken,
      loginTokenPayload,
    }: {
      loginToken: string;
      loginTokenPayload: LoginTokenPayload;
    }) => {
      signInAction({ loginToken, loginTokenPayload });

      localStorage.setItem('loginToken', loginToken);
    },
    [signInAction]
  );

  const signOut = useCallback(() => {
    signOutAction();

    localStorage.clear();
  }, [signOutAction]);

  return { signIn, signOut };
}
