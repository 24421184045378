/* MUI */
import { css } from '@emotion/react';
import styled from '@emotion/styled';

/* Models */
import type { TRoom } from '../../../../models/chatroom/room';
/* Components */
import MessageInput from './MessageInput';
import MessageList from './MessageList';
import MessagesHeader from './MessagesHeader';

/* Styled Components ---------------------------------------------- */
const MessagesContainer = styled.section`
  /* height: ${(props) => css`calc(100vh - ${props.theme.header.height})`}; */
  height: ${(props) => css`calc(100vh)`};
  display: grid;
  grid-template-rows: max-content 1fr max-content;

  ${(props) => props.theme.breakpoints.down('xs')} {
    height: 100%;
  }
`;

/* //////////////////////////////////////////////////////////////// */
type Props = {
  room: TRoom;
  onToggleShowUserDetail: () => void;
};

export default function Messages({ room, onToggleShowUserDetail }: Props) {
  /* JSX ---------------------------------------------------------- */
  return (
    <MessagesContainer>
      <MessagesHeader onToggleShowUserDetail={onToggleShowUserDetail} />
      <MessageList key={room.roomId} currentRoomId={room.roomId} />
      <MessageInput room={room} />
    </MessagesContainer>
  );
}
