/* React */
import React, { useState, useEffect } from 'react';

/* //////////////////////////////////////////////////////////////// */
export default function useLiffLogin(): void {
    // States or Parameters
    const oryLoginUrl = 'https://login.lightup.tw';
    const customApiUrl = 'https://login.ms.lightup.tw/user-client';
    const logoutStorage = window.sessionStorage.getItem('logout') ?? undefined;
    const hasLoggedIn = window.sessionStorage.getItem('login') === 'true' ?? false;
    const isLineBrowser = /Line/i.test(navigator.userAgent);
    const [accessToken, setAccessToken] = useState(undefined); // 初始狀態設為undefined，經getLoginToken檢查後若為空字串，再將其設入。
    const [checkedLoginToken, setCheckedLoginToken] = useState(false);

    // Get new token
    const getLoginToken = async() => {
        try{
            const url = `${customApiUrl}/oauth/token`;
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            const responseJson = await response.json();
            const accessToken = responseJson?.access_token ?? "";
            const refreshToken = responseJson?.refresh_token ?? "";
            const expiry = responseJson?.expires_at ?? "";
            window.sessionStorage.setItem('accesstoken', accessToken);
            window.sessionStorage.setItem('refresh_token', refreshToken);
            window.sessionStorage.setItem('expiry', expiry);
            setAccessToken(accessToken);
            setCheckedLoginToken(true);
            return true; 
        } catch(error) {
            console.log(`getLoginToken error: ${error}`);
        }
    }

    // Login
    const login = async(event: React.MouseEvent | undefined) => {
        event?.preventDefault();
        const returnToUrl = encodeURI(`https://${window.location.host}`); // 登入後要返回的頁面
        const loginUrl = `${oryLoginUrl}/oauth/?return_to=${returnToUrl}`; // OAuth登入頁面
        const setStorage = async() => {
            window.sessionStorage.removeItem('logout');
            window.sessionStorage.setItem('login', 'true');
        }
        const promiseFunc = () => {
            return new Promise(async(resolve) => {
                await setStorage();
                resolve(true);
            })
        }
        await promiseFunc().then((bool) => {
            if (bool) {
                window.location.href = loginUrl;
            }
        });
    }

    // LIFF登入
    const liffLogin = async() => {
        if (isLineBrowser) {
            if (! hasLoggedIn && ! accessToken) { // 尚未用LINE瀏覽器登入過 => LIFF登入
                login(undefined); 
            }
        }
    } 
    useEffect(() => {
        if (! logoutStorage && isLineBrowser) getLoginToken(); // 第一次進入此hook呼叫get token，如果有取得Token則直接登入; 登出後不要取Token
    }, [])
    useEffect(() => {
        if (! logoutStorage && ! accessToken && checkedLoginToken && isLineBrowser) liffLogin(); // 非登出狀態且未拿到access token且真正執行過getLoginToken，才進入Liff登入流程        
    }, [accessToken])
}