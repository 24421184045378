/* React */
import { useEffect, useMemo } from 'react';
/* MUI */
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

/* Models */
import type { TMessageItem } from '../../../../models/chatroom/messages';
import type { TRoom } from '../../../../models/chatroom/room';

const channelMap: Record<string, TMessageItem['channelType']> = {
  chatId: 'CHAT',
  lineId: 'LINE',
};

/* //////////////////////////////////////////////////////////////// */
type Props = {
  room: TRoom;
  channelType: TMessageItem['channelType'];
  onChannelTypeChange: (channelType: TMessageItem['channelType']) => void;
};

export default function ChannelSelector({
  room,
  channelType,
  onChannelTypeChange,
}: Props) {
  const selectableChannels = useMemo<TMessageItem['channelType'][]>(() => {
    const result: TMessageItem['channelType'][] = [];
    Object.keys(channelMap).forEach((key) => {
      if (key in room) result.push(channelMap[key]);
    });
    return result;
  }, [room]);

  const handleChannelTypeChange = (d: SelectChangeEvent<unknown>) => {
    onChannelTypeChange(d.target.value as TMessageItem['channelType']);
  };

  // Set default channelType when selectableChannels change
  useEffect(() => {
    onChannelTypeChange(selectableChannels[0]);
  }, [selectableChannels, onChannelTypeChange]);

  /* JSX ---------------------------------------------------------- */
  return (
    <Select
      sx={{ height: '2rem' }}
      size="small"
      value={channelType}
      defaultValue={selectableChannels[0]}
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
      onChange={handleChannelTypeChange}
    >
      {selectableChannels.map((channel) => (
        <MenuItem key={channel} value={channel}>
          {channel}
        </MenuItem>
      ))}
    </Select>
  );
}
