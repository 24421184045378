import { useState, useEffect, useCallback } from 'react';
import Logo from './logo.svg'
import './App.css';
import Messages from './features/chatroom/components/Messages/_Messages'
import { ThemeProvider } from '@emotion/react';
import createTheme from './theme';
import { Toaster } from 'react-hot-toast';

/* MUI */
import { Drawer, useTheme } from '@mui/material';
import styled from '@emotion/styled';

/* Router */
import { Routes, Route, Navigate, useNavigate, useParams } from 'react-router-dom';

/* Components */
import { TRoom } from './models/chatroom/room';
import Dialog from './components/ui/Dialog/_Dialog';

/* Hooks */
import useDialog from './hooks/useDialog';
import useSocket from './hooks/useSocket';
import useStoreSocket from './hooks/useStoreSocket';
import useAppSetting from './hooks/useAppSetting';
import useStoreAuth from './hooks/useStoreAuth';
import useLogin from './hooks/useLogin';
import useLogout from './hooks/useLogout';
import useLiffLogin from './hooks/useLiffLogin';

/* i18n */
import './lib/locales/i18n'


function App() {
  const [showUserDetail, setShowUserDetail] = useState<boolean>(false);
  const [room, setRoom] = useState<TRoom | null>(null);

  const { appSet } = useAppSetting();

  const { roomId } = useParams(); // for connect to chat room
  const theme = useTheme();


  const { error } = useDialog();
  const { socket } = useStoreSocket();
  useSocket();

  const login = useLogin; // 在組件主體建立useLogin實例
  const logout = useLogout; // 在組件主體建立useLogout實例
  useLiffLogin();

  /* Styled Components ---------------------------------------------- */
  const Container = styled.div`
  display: flex;
  overflow: hidden;
  `;
  const MainContentOnSmallerScreen = styled('div')`
  flex-grow: 1;
  `;
  const MainContentOnLargerScreen = styled('div')`
  flex-grow: 1;
  display: grid;
  grid-template-columns: 20rem 1fr;
  height: 100%;
  `;
  const MessagesOnSmallerScreen = styled('div')`
  z-index: ${(props) => props.theme.sidebar.index};
  position: fixed;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100%;
  `;
  const UserDetailOnLargerScreen = styled('div')<{ showUserDetail: boolean; }>`
  display: none;
  ${(props) => props.theme.breakpoints.up('md')} {
    display: block;
    transition: all 200ms ease-in;
  }
  width: ${(props) => (props.showUserDetail ? '20rem' : '0')};
  `;

  const handleGetRoomFromSocket = useCallback((data: TRoom) => {
    setRoom(data);
  }, []);
  const handleToggleShowUserDetail = () => {
    setShowUserDetail((show) => !show);
  };
  const handleCloseUserDetail = () => {
    setShowUserDetail(false);
  };

  useEffect(() => {
    if (!socket || !roomId) return;
    socket.emit('join-room', { roomId });
    socket.on('res-room', handleGetRoomFromSocket); // [NOTE] when joined new room socket will emit `res-room` event
    // eslint-disable-next-line consistent-return
    return () => {
      socket.emit('leave-room', { roomId });
      socket.off('res-room', handleGetRoomFromSocket);
    };
  }, [socket, handleGetRoomFromSocket, roomId]);

  /* test room */
  const roomData: TRoom = {
    docType: 'room',
    roomId: 'yourRoomId',
    messages: [],
    tags: [],
    userId: 'yourUserId',
    chatId: 'yourChatId',
    chatProfile: { id: 'yourChatProfileId' },
    lineId: 'yourLineId',
    lineProfile: { id: 'yourLineProfileId' },
    lastMsg: {
      userType: 'CS',
      sendTo: '',
      channelType: 'CHAT',
      data: {  
        type: 'text',
        text: ''
      },
      senderId: '',
      createdAt: + new Date(),
      // 添加其他 TMessageItem 的属性...
    },
  };

  return (
    <div className="App">
      {appSet ? (
        <ThemeProvider theme={createTheme('DEFAULT')}>
          <header>
            <Dialog />        
            <Toaster
              position="top-center"
              gutter={12}
              containerStyle={{ margin: '8px' }}
              toastOptions={{
                success: {
                  duration: 3 * 1000,
                },
                error: {
                  duration: 5 * 1000,
                },
                style: {
                  maxWidth: '500px',
                  padding: '16px 24px',
                  fontSize: '16px',
                  color: 'black',
                  backgroundColor: 'white',
                },
              }}
            />
          </header>
          <Container className="App-header">
            { ! window.sessionStorage.getItem('accesstoken') ? ( // 未登入，顯示登入按鈕；未登入且用LINE瀏覽器登入會自動登入
              <>
                <a title="Login" style={{cursor: 'pointer'}} onClick={(e) => {login(e)}}><img src={Logo} className="App-logo" alt="logo"/></a>
                <p>
                  Click to Login
                </p>
              </>
            ) : window.sessionStorage.getItem('accesstoken') ? ( // 已登入，顯示聊天室
              <>
                <MainContentOnSmallerScreen>
                  <MessagesOnSmallerScreen>
                    <Messages
                      room={roomData}
                      onToggleShowUserDetail={handleToggleShowUserDetail}
                    />
                  </MessagesOnSmallerScreen>
                </MainContentOnSmallerScreen>
              </>
            ) : ( // LINE瀏覽器自動登入失敗 => 重新登入
              <>
                <a title="Login" style={{cursor: 'pointer'}} onClick={(e) => {login(e)}}><img src={Logo} className="App-logo" alt="logo"/></a>
                <p>
                  Click to Login
                </p>
              </>
            )}                      
          </Container>
        </ThemeProvider>
      ) : null}
    </div>
  );
}

export default App;

