/* MUI */
import styled from '@emotion/styled';

/* Types */
import type { TMessageDataText } from '../../../../features/chatroom/types/message-content';

/* Styled Components ---------------------------------------------- */
const Typography = styled('div')`
  word-break: break-word;
  white-space: pre-line;
`;

/* //////////////////////////////////////////////////////////////// */
type Props = {
  messageData: TMessageDataText;
};

export default function MessageContentText({ messageData }: Props) {
  /* JSX ---------------------------------------------------------- */
  return <Typography>{messageData.text}</Typography>;
}
