/* React */
import React from 'react';

/* //////////////////////////////////////////////////////////////// */
export default function useLogout(event: React.MouseEvent | undefined): void {
    // States or Parameters
    const oryLoginUrl = 'https://login.lightup.tw';

    // Logout
    const logout = async(event: React.MouseEvent | undefined) => {
      event?.preventDefault();
      const clearStorage = async() => {
        window.sessionStorage.removeItem('accesstoken');
        window.sessionStorage.removeItem('refresh_token');
        window.sessionStorage.removeItem('expiry');
        window.sessionStorage.removeItem('login');
        window.sessionStorage.setItem('logout', 'true');
      }
      const promiseFunc = () => {
        return new Promise(async(resolve) => {
          await clearStorage();
          resolve(true);
        })
      }
      await promiseFunc().then((bool) => {
        const logoutReturnTo = encodeURI(`https://${window.location.host}?logout=true`);
        if (bool) {
          window.location.href=`${oryLoginUrl}?logout=true&return_to=${logoutReturnTo}`;
        }
      });
    }
    logout(event);
}