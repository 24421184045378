/* Redux */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DialogState = {
  isDialogOpen: boolean;
  type: 'error' | 'confirm';
  title: string;
  message: string;
  onConfirmCb: () => void;
};

export type OpenDialogPayload = {
  type: DialogState['type'];
  title: string;
  message: string;
  onConfirmCb?: () => void;
};

const initialState: DialogState = {
  isDialogOpen: false,
  type: 'error',
  title: '',
  message: '',
  onConfirmCb: () => {},
};

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    openDialog: (state, action: PayloadAction<OpenDialogPayload>) => {
      state.isDialogOpen = true;
      state.type = action.payload.type;
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.onConfirmCb = action.payload.onConfirmCb || (() => {});
    },
    closeDialog: (state) => {
      state.isDialogOpen = false;
      state.onConfirmCb = () => {};
    },
  },
});

export const dialogAction = dialogSlice.actions;
