/* Redux */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
/* Socket */
import type { Socket } from 'socket.io';

export type SocketState = {
  socket: Socket | null;
};

export type ConnectPayload = {
  socket: Socket;
};

const initialState: SocketState = {
  socket: null,
};

export const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    connect: (state, action: PayloadAction<ConnectPayload>) => {
      state.socket = action.payload.socket;
    },
    disconnect: (state) => {
      state.socket = null;
    },
  },
});

export const socketAction = socketSlice.actions;
