/* React */
import { useState } from 'react';
/* i18n */
import { useTranslation } from 'react-i18next';
/* MUI */
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import styled from '@emotion/styled';

/* Hooks */
import useStoreNavMenu from '../../../hooks/useStoreNavMenu';

/* Styled Components ---------------------------------------------- */
const StyledLocaleIcon = styled('img')`
  width: 22px;
  height: 22px;
  border-radius: 50%;
`;

type LanguageOptions = {
  langCode: string;
  title: string;
}[];

const languageOptions: LanguageOptions = [
  {
    langCode: 'en',
    title: 'English',
  },
  {
    langCode: 'zh_tw',
    title: '繁體中文',
  },
  {
    langCode: 'zh_cn',
    title: '简体中文',
  },
];

/* //////////////////////////////////////////////////////////////// */
export default function NavbarLanguageDropdown() {
  const [anchorMenu, setAnchorMenu] = useState<(EventTarget & Element) | null>(
    null
  );

  const { i18n, t } = useTranslation();

  const { curOpenMenu, openMenu, closeMenu } = useStoreNavMenu();

  const isMenuOpen = curOpenMenu === 'language';

  const handleOpenMenu = () => {
    openMenu({ curOpenMenu: 'language' });
  };
  const handleCloseMenu = () => {
    closeMenu();
  };
  const handleToggleMenu = (event: React.SyntheticEvent) => {
    handleOpenMenu();
    setAnchorMenu(event.currentTarget);
  };
  const handleLanguageChange = (langCode: string) => {
    i18n.changeLanguage(langCode);
    localStorage.setItem('locale', langCode);
    handleCloseMenu();
  };

  /* JSX ---------------------------------------------------------- */
  return (
    <>
      <Tooltip title={t('navbar__language-dropdown__tooltip')}>
        <IconButton
          size="large"
          color="inherit"
          onClick={handleToggleMenu}
          aria-owns={isMenuOpen ? 'menu-appbar--language' : undefined}
          aria-haspopup="true"
        >
          <StyledLocaleIcon
            src={`/img/locale/${i18n.language}.png`}
            alt="locale"
          />
        </IconButton>
      </Tooltip>

      <Menu
        id="menu-appbar--language"
        open={isMenuOpen}
        onClose={handleCloseMenu}
        anchorEl={anchorMenu}
      >
        {languageOptions.map((languageOption) => (
          <MenuItem
            key={languageOption.langCode}
            onClick={() => handleLanguageChange(languageOption.langCode)}
          >
            {languageOption.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
