/* Date fns */
import { format } from 'date-fns';
/* MUI */
import styled from '@emotion/styled';

/* Styled Components ---------------------------------------------- */
const StyledDateSeparator = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  font-size: 13px;
  font-weight: 500;
  color: #a8a8a8;

  &:before,
  &:after {
    display: block;
    box-sizing: border-box;
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: #e5e5e6;
  }
`;

/* //////////////////////////////////////////////////////////////// */
type Props = {
  dateTime: number;
};

export default function DateSeparator({ dateTime }: Props) {
  /* JSX ---------------------------------------------------------- */
  return (
    <StyledDateSeparator>{format(dateTime, 'yyyy/MM/dd')}</StyledDateSeparator>
  );
}
