/* Types */
import type { TMessageDataAudio } from '../../../../features/chatroom/types/message-content';

/* //////////////////////////////////////////////////////////////// */
type Props = {
  messageData: TMessageDataAudio;
};

export default function MessageContentAudio({ messageData }: Props) {
  /* JSX ---------------------------------------------------------- */
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio controls controlsList="nofullscreen" src={messageData.src}>
      {/* unplayable */}
      {/* <source src={messageData.src} type="audio/m4a" /> */}
    </audio>
  );
}
