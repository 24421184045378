/* eslint-disable @typescript-eslint/naming-convention -- 在這裡使用 BEM 表記 */

import type { Translation } from './en';

export const translation_zh_cn: Translation = {
  /* General ------------------------------------------------------ */
  'general__error-messages__unknown': '发生错误，请稍后再试',

  /* Dialog ------------------------------------------------------- */
  'dialog__title--error': '错误',
  'dialog__title--confirm': '确认',
  'dialog__button--okay': '确定',
  'dialog__button--cancel': '取消',

  /* Navbar ------------------------------------------------------- */
  'navbar__language-dropdown__tooltip': '语言',

  'navbar__user-dropdown__tooltip': '帐号',
  'navbar__user-dropdown__menu__logout': '登出',
  'navbar__user-dropdown__dialog__message': '确定要登出？',

  /* Sidebar ------------------------------------------------------ */
  sidebar__chatroom: '聊天室',

  sidebar__line: 'Line',
  sidebar__line__message: '消息',
  sidebar__line__message__statistics: '统计数据',
  'sidebar__line__message__message-template': '单一消息模板',
  'sidebar__line__message__send-message': '创建消息组',
  'sidebar__line__message__message-list': '消息列表',
  sidebar__line__message__schedule: '消息排程',
  'sidebar__line__rich-menu': 'Rich menu',

  'sidebar__push-notification': '推播通知',
  'sidebar__push-notification__push': '推播',

  sidebar__tag: '标签',

  /* Chatroom ----------------------------------------------------- */
  'chatroom__message-header__i18n-button-text': '语言',
  'chatroom__message-input__submit-text': '发送',
  'chatroom__message-input__placeholder':
    '按 Enter 发送消息，使用 Shift + Enter 键可进行换行',
  'chatroom__file-uploader__tooltip': '上传文件',

  /* Chatroom user detail ----------------------------------------- */
  'chatroom__user-detail__heading': '详细资料',

  'chatroom__user-detail__tag__heading': '标签',
  'chatroom__user-detail__tag-modal__selected-tags__heading': '已选择的标签',
  'chatroom__user-detail__tag-modal__selected-tags__empty': '没有已选择的标签',
  'chatroom__user-detail__tag-modal__selectable-tags__heading': '可选择的标签',
  'chatroom__user-detail__tag-modal__selectable-tags__empty':
    '没有可选择的标签',

  /* LINE General ------------------------------------------------- */
  'line__general__send-method--broadcast': '广播消息',
  'line__general__send-method--multicast': '依标签发送',

  'line__general__send-time--immediately': '立即发送',
  'line__general__send-time--reservation': '预约发送',

  'line__general__message-type--text': '文字消息',
  'line__general__message-type--flex': 'Flex消息',

  'line__general__field-name--estimated-sending-time': '预约时间',
  'line__general__field-name--send-method': '发送方式',
  'line__general__field-name--title': '标题 (内部使用)',
  'line__general__field-name--message-type': '消息类型',
  'line__general__field-name--create-user-name': '创建者',
  'line__general__field-name--created-at': '创建于',

  /* LINE Template ------------------------------------------------ */
  line__template__heading: '单一消息模板',
  'line__template__button--create': '创建模板',
  line__template__filter__search__label: '标题 (内部使用)',
  line__template__filter__search__placeholder: '搜索标题 (最长 20 字)',

  'line__template__table__confirm-modal--delete': '删除该模板?',

  'line__template__form__heading--create': '创建模板',
  'line__template__form__heading--edit': '编辑模板',
  'line__template__form__submit-button--create': '创建',
  'line__template__form__submit-button--edit': '编辑',
  'line__template__form__error-message__title--empty': '标题不可为空',

  'line__template__toast--success__create-template': '成功创建模板',
  'line__template__toast--success__edit-template': '成功编辑模板',
  'line__template__toast--success__delete-template': '成功删除模板',

  /* LINE Send message -------------------------------------------- */
  'line__send-message__title__description':
    '消息组为 Line 的计费单位，一则消息组由最多 5 则单一消息组成',
  'line__send-message__title--create': '创建消息',
  'line__send-message__title--edit': '编辑消息',

  'line__send-message__form__label__template-selector': '选择模板',
  'line__send-message__form__submit-button-text--create': '创建消息组',
  'line__send-message__form__submit-button-text--edit': '编辑消息组',
  'line__send-message__form__add-message-button-text': '新增单一消息',

  'line__send-message__form__error-messages--purpose': '请输入标题',
  'line__send-message__form__error-messages--date': '请选择发送日期',
  'line__send-message__form__error-messages--text__text': '请输入消息',
  'line__send-message__form__error-messages--flex-message__alt-text':
    '请输入 Alt text',
  'line__send-message__form__error-messages--flex-message__contents':
    '请输入有效的 flex message',

  'line__send-message__dialog--error__get-message': '找无该笔消息',

  'line__send-message__toast--success__create-message': '成功创建消息',
  'line__send-message__toast--success__edit-message': '成功编辑消息',

  /* LINE Messages ------------------------------------------------ */
  line__messages__heading: '消息列表',
  'line__messages__filter--search__label': '标题 (内部使用)',
  'line__messages__filter--search__placeholder': '搜索标题 (最长 20 字)',

  'line__messages__filter--send-method__label': '发送方式',
  'line__messages__send-method-selector--all': '全部',

  'line__messages__table__header--approving-status': '审核状态',
  'line__messages__table__header--sent-at': '发送时间',

  'line__messages__table__estimated-sending-time--send-immediately':
    '立即发送',
  'line__messages__table__approving-status--wait': '等待中',
  'line__messages__table__approving-status--agree': '同意',
  'line__messages__table__approving-status--reject': '拒绝',
  'line__messages__table__sending-status--cancel': '取消发送',
  'line__messages__table__sending-status--sent': '已发送',
  'line__messages__table__sending-status--wait': '等待中',

  /* LINE Message detail modal content ---------------------------- */
  'line__message-detail__edit-message-button': '编辑消息',
  'line__message-detail__form--confirm__title': '审核消息',
  'line__message-detail__form--confirm__placeholder': '回馈消息 (最多 200 字)',
  'line__message-detail__form--confirm__dialog--agree__message': '同意发送消息?',
  'line__message-detail__form--confirm__dialog--reject__message':
    '拒绝发送消息?',
  'line__message-detail__form--confirm__button--agree': '同意发送',
  'line__message-detail__form--confirm__button--reject': '拒绝发送',

  'line__message-detail__form--cancel__title': '取消发送消息',
  'line__message-detail__form--cancel__placeholder': '回馈消息 (最多 200 字)',
  'line__message-detail__form--cancel__dialog__message': '取消发送消息?',
  'line__message-detail__form--cancel__button': '取消发送',

  'line__message-detail__table__header--approving-status': '审核状态',
  'line__message-detail__table__header--sent-at': '发送时间',
  'line__message-detail__table__header--confirmed-at': '审核时间',
  'line__message-detail__table__header--confirmed-user-name': '审核人员',
  'line__message-detail__table__header--confirmed-comment': '审核消息',

  'line__message-detail__table__content__estimated-sending-time--immediately':
    '立即发送',

  'line__message-detail__table__messages__label': '消息',

  'line__message-detail__toast--success__cancel-send': '已取消发送消息',
  'line__message-detail__toast--success__confirm-send': '已审核消息',

  /* LINE Messages schedule --------------------------------------- */
  'line__message-schedule__calendar__button-text--today': '今日',
  'line__message-schedule__calendar__button-text--month': '月',
  'line__message-schedule__calendar__button-text--week': '周',
  'line__message-schedule__calendar__button-text--day': '日',

  /* Push Notification General ------------------------------------ */
  'push-notification__general__field-name__title': '标题',
  'push-notification__general__field-name__body': '内容',

  /* Push Notification Push --------------------------------------- */
  'push-notification__push__heading': '推播通知',
  'push-notification__push__from__error-messages__title--no-empty':
    '标题不可为空',
  'push-notification__push__from__error-messages__body--no-empty':
    '内容不可为空',

  'push-notification__push__toast--success__push-notification':
    '成功推播通知',

  /* Tag General -------------------------------------------------- */
  'tag__general__field-name__tag-type': '标签类型',
  'tag__general__field-name__tag-name': '标签名称',
  'tag__general__field-name__priority': '优先级',
  'tag__general__field-name__description': '描述',
  'tag__general__field-name__quantity-tagged': '已标记数量',

  /* Tag table ---------------------------------------------------- */
  tag__table__heading: '标签',
  'tag__table__button--create': '新增标签',
  tag__table__filter__search__label: '标签 / 描述 / 创建者',
  tag__table__filter__search__placeholder: '搜索标签 (最长 20 字)',

  /* Tag form ----------------------------------------------------- */
  'tag__form__heading--create': '新增标签',
  'tag__form__heading--edit': '编辑标签',
  'tag__form__submit-button--create': '新增标签',
  'tag__form__submit-button--edit': '编辑标签',

  'tag__confirm-modal--delete-tag': '删除该标签?',

  'tag__toast--success__create-tag': '成功新增标签',
  'tag__toast--success__edit-tag': '成功编辑标签',
  'tag__toast--success__delete-tag': '成功删除标签',
};