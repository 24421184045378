/* Hooks */
import useDetectMessageType from '../../../../features/chatroom/hooks/useDetectMessageType';
/* Models */
import type { TMessageItem } from '../../../../models/chatroom/messages';
/* Components */
import MessageContentText from './MessageContentText';
import MessageContentImage from './MessageContentImage';
import MessageContentVideo from './MessageContentVideo';
import MessageContentAudio from './MessageContentAudio';
import MessageContentFile from './MessageContentFile';

/* //////////////////////////////////////////////////////////////// */
type Props = {
  message: TMessageItem;
};

export default function MessageContent({ message }: Props) {
  const { type, messageData } = useDetectMessageType(message);

  /* JSX ---------------------------------------------------------- */
  if (type === 'text') {
    return <MessageContentText messageData={messageData} />;
  }
  if (type === 'image') {
    return <MessageContentImage messageData={messageData} />;
  }
  if (type === 'video') {
    return <MessageContentVideo messageData={messageData} />;
  }
  if (type === 'audio') {
    return <MessageContentAudio messageData={messageData} />;
  }
  if (type === 'file') {
    return <MessageContentFile messageData={messageData} />;
  }
}
