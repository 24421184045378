/* MUI */
import styled from '@emotion/styled';

/* Hooks */
import useStoreLightBox from '../../../../features/chatroom/hooks/useStoreLightBox';
/* Types */
import { TMessageDataImage } from '../../../../features/chatroom/types/message-content';

/* Styled Components ---------------------------------------------- */
const Figure = styled('figure')`
  height: 11rem;
  width: 11rem;
  cursor: pointer;
`;
const Image = styled('img')`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

/* //////////////////////////////////////////////////////////////// */
type Props = {
  messageData: TMessageDataImage;
};

export default function MessageContentImage({ messageData }: Props) {
  const { onOpenLightBox: onOpenLightBoxCtx } = useStoreLightBox();

  const handleOpenLightBox = () => {
    onOpenLightBoxCtx(messageData.src);
  };

  /* JSX ---------------------------------------------------------- */
  return (
    <Figure onClick={handleOpenLightBox}>
      <Image src={messageData.src} alt={messageData.src} loading="lazy" />
    </Figure>
  );
}
