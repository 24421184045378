/* MUI */
import { createTheme as createMuiTheme } from '@mui/material';

import { THEMES } from '../const/themes';

import breakpoints from './breakpoints';
import components from './components';
import shadows from './shadows';
import typography from './typography';
import variants from './variants';

const createTheme = (themeName: keyof typeof THEMES) => {
  const themeConfig =
    variants.find((variant) => variant.name === themeName) || variants[0];

  return createMuiTheme(
    {
      spacing: 4,
      breakpoints,
      components,
      shadows,
      typography,
      palette: themeConfig.palette,
    },
    {
      ...themeConfig,
    }
  );
};

export default createTheme;
