/* Merge */
import merge from 'deepmerge';
/* MUI */
import type { PaletteOptions } from '@mui/material';
import { grey } from '@mui/material/colors';

/* CONST */
import { THEMES } from '../const/themes';

const customBlue = {
  50: '#e9f0fb',
  100: '#c8daf4',
  200: '#a3c1ed',
  300: '#7ea8e5',
  400: '#6395e0',
  500: '#4782da',
  600: '#407ad6',
  700: '#376fd0',
  800: '#2f65cb',
  900: '#2052c2 ',
};

export type Variant = {
  name: string;
  palette: PaletteOptions;
  header: {
    index: number;
    height: string;
    color: string;
    background: string;
    shadow: string;
  };
  footer: {
    color: string;
    background: string;
  };
  sidebar: {
    index: number;
    width: string;
    color: string;
    background: string;
  };
  loadingSpinner: {
    index: number;
    backdropColor: string;
  };
  table: {
    border: string;
  };
  chatroom: {
    index: number;
    border: string;
  };
};

const defaultVariant: Variant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: 'light',
    primary: {
      main: customBlue[700],
      contrastText: '#fff',
    },
    secondary: {
      main: customBlue[500],
      contrastText: '#fff',
    },
    background: {
      default: '#f7f9fc',
      paper: '#fff',
    },
    text: {
      primary: '#000000DE',
      secondary: '#00000088',
    },
  },
  header: {
    index: 20,
    height: '64px',
    color: grey[500],
    background: '#FFF',
    shadow: 'rgba(17, 17, 26, 0.1) 0px 0px 8px',
  },
  footer: {
    color: grey[500],
    background: '#FFF',
  },
  sidebar: {
    index: 30,
    width: '250px',
    color: grey[200],
    background: '#233044',
  },
  loadingSpinner: {
    index: 50,
    backdropColor: '#fffff10',
  },
  table: {
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  chatroom: {
    index: 25,
    border: '1px solid #e5e5e6',
  },
};

const darkVariant: Variant = merge(defaultVariant, {
  name: THEMES.DARK,
  palette: {
    mode: 'dark',
    primary: {
      main: customBlue[600],
      contrastText: '#FFF',
    },
    background: {
      default: '#1B2635',
      paper: '#233044',
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.95)',
      secondary: 'rgba(255, 255, 255, 0.5)',
    },
  },
  header: {
    color: grey[300],
    background: '#1B2635',
  },
  footer: {
    color: grey[300],
    background: '#233044',
  },
});

const variants: Array<Variant> = [defaultVariant, darkVariant];

export default variants;
