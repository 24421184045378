/* File size */
import { filesize } from 'filesize';
/* MUI */
import { Icon } from '@mui/material';
import { InsertDriveFile } from '@mui/icons-material';
import styled from '@emotion/styled';

/* Hooks */
import useDialog from '../../../../hooks/useDialog';
/* Types */
import type { TMessageDataFile } from '../../../../features/chatroom/types/message-content';

/* Styled Components ---------------------------------------------- */
const Container = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const DownloadIcon = styled(Icon)`
  margin: 0 0.5rem;
  color: ${(props) => props.theme.palette.text.secondary};
`;
const FileInfo = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  padding: 0.25rem;
  font-size: 0.875rem;

  & .file-name {
    max-width: 15rem;
    min-width: 8rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  & .file-size {
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;

/* //////////////////////////////////////////////////////////////// */
type Props = {
  messageData: TMessageDataFile;
};

export default function MessageContentFile({ messageData }: Props) {
  const fileUrl = messageData.originalContentUrl;
  const fileName = messageData.originalFileName;
  const displayFileSize = messageData.fileSize
    ? filesize(messageData.fileSize, { base: 2 })
    : '--';

  const dialog = useDialog();

  const handleDownloadFile = async () => {
    try {
      const res = await fetch(fileUrl);
      const blob = await res.blob();
      if (!res.ok) throw new Error();

      const blobURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobURL;
      a.download = fileName;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(blobURL);
    } catch (error) {
      dialog.error({
        message: `An error occurred while downloading ${fileName}`,
      });
    }
  };

  /* JSX ---------------------------------------------------------- */
  return (
    <Container onClick={handleDownloadFile}>
      <DownloadIcon>
        <InsertDriveFile />
      </DownloadIcon>
      <FileInfo>
        <p className="file-name">{fileName}</p>
        <p className="file-size">{displayFileSize}</p>
      </FileInfo>
    </Container>
  );
}
