/* Query */
import { useMutation } from '@tanstack/react-query';

/* Hooks */
import useHandleHttpError from '../../../hooks/useHandleHttpError';
/* Services */
import { sendMessage } from '../../../features/chatroom/services/send-message';

/* //////////////////////////////////////////////////////////////// */
export default function useSendMessage() {
  const {
    mutate: onSendMessage,
    data,
    error,
    isPending,
  } = useMutation({
    mutationFn: sendMessage,
  });

  useHandleHttpError({ error });

  return { onSendMessage, data, error, isPending };
}
