/* React */
import { useContext } from 'react';

/* Store */
import { LightBoxContext } from '../../../features/chatroom/store/LightBoxContext';

/* //////////////////////////////////////////////////////////////// */
export default function useStoreLightBox() {
  return useContext(LightBoxContext);
}
