/* Zod */
import z from 'zod';

/* Types */
import type { TMessageItem } from '../../../models/chatroom/messages';

type TResPresignedPolicy = {
  postURL: string;
  formData: {
    bucket: string;
    key: string;
    'x-amz-meta-channelId': string;
    'x-amz-meta-originName': string;
    'x-amz-meta-channelType': string;
    'x-amz-meta-userId': string;
    'x-amz-meta-messageType': string;
    'x-amz-meta-userType': string;
    'x-amz-date': string;
    'x-amz-algorithm': string;
    'x-amz-credential': string;
    'x-amz-signature': string;
    policy: string;
  };
};

const getPolicyErrorSchema = z.intersection(
  z.object({
    message: z.string(),
  }),
  z.union([
    z.object({
      errorCode: z.literal('file_size_too_large'),
      allowedMaxSize: z.number(),
    }),
    z.object({
      errorCode: z.literal('file_size_too_large'),
      allowedType: z.array(z.string()),
    }),
  ])
);

export const uploadFile = async ({
  file,
  channelType,
  roomId,
  userType,
  userId,
}: {
  file: File;
  channelType: TMessageItem['channelType'];
  roomId: string;
  userType: TMessageItem['userType'];
  userId: string;
}) => {
  const fileType = file.type.split('/').at(0)!;
  let messageType = 'file';
  if (fileType === 'image') messageType = 'photo';
  if (['video', 'audio'].includes(fileType)) messageType = fileType;

  const urlSearchParam = new URLSearchParams();
  urlSearchParam.set('name', file.name);
  urlSearchParam.set('fileSize', String(file.size));
  urlSearchParam.set('fileType', file.type);
  urlSearchParam.set('messageType', messageType);
  urlSearchParam.set('channelType', channelType);
  urlSearchParam.set('roomId', roomId);
  urlSearchParam.set('userType', userType);
  urlSearchParam.set('userId', userId);

  // (1) Presigned Policy
  const resPresignedPolicy = await fetch(
    `${
      process.env.REACT_APP_PRESIGNED_POLICY_URL
    }?${urlSearchParam.toString()}`
  );
  const bodyPresignedPolicy = await resPresignedPolicy.json();

  if (!resPresignedPolicy.ok) {
    const parsedError = getPolicyErrorSchema.safeParse(bodyPresignedPolicy);
    if (!parsedError.success)
      throw new Error('Unsupported file type or file size is too large.');
    throw new Error(parsedError.data.message);
  }

  // (2) Actual upload file
  const formData = new FormData();
  Object.entries((bodyPresignedPolicy as TResPresignedPolicy).formData).forEach(
    ([key, value]) => {
      formData.append(key, value);
    }
  );
  formData.append('file', file);

  const resUploadFile = await fetch(bodyPresignedPolicy.postURL, {
    method: 'POST',
    body: formData,
  });

  if (!resUploadFile.ok)
    throw new Error('An error occurred during file upload.');
};
