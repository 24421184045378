/* eslint-disable @typescript-eslint/naming-convention -- use BEM notation here */

import type { Translation } from './en';

export const translation_zh_tw: Translation = {
  /* General ------------------------------------------------------ */
  'general__error-messages__unknown': '發生錯誤，請稍後在試',

  /* Dialog ------------------------------------------------------- */
  'dialog__title--error': '錯誤',
  'dialog__title--confirm': '確認',
  'dialog__button--okay': '確定',
  'dialog__button--cancel': '取消',

  /* Navbar ------------------------------------------------------- */
  'navbar__language-dropdown__tooltip': '語言',

  'navbar__user-dropdown__tooltip': '帳號',
  'navbar__user-dropdown__menu__logout': '登出',
  'navbar__user-dropdown__dialog__message': '確定要登出？',

  /* Sidebar ------------------------------------------------------ */
  sidebar__chatroom: '聊天室',

  sidebar__line: 'Line',
  sidebar__line__message: '訊息',
  sidebar__line__message__statistics: '統計數據',
  'sidebar__line__message__message-template': '單一訊息模板',
  'sidebar__line__message__send-message': '建立訊息組',
  'sidebar__line__message__message-list': '訊息列表',
  sidebar__line__message__schedule: '訊息排程',
  'sidebar__line__rich-menu': 'Rich menu',

  'sidebar__push-notification': '推播通知',
  'sidebar__push-notification__push': '推播',

  sidebar__tag: '標籤',

  /* Chatroom ----------------------------------------------------- */
  'chatroom__message-header__i18n-button-text': '語言',
  'chatroom__message-input__submit-text': '傳送',
  'chatroom__message-input__placeholder':
    '按 Enter 送出訊息，使用 Shift + Enter 鍵可進行換行',
  'chatroom__file-uploader__tooltip': '上傳檔案',

  /* Chatroom user detail ----------------------------------------- */
  'chatroom__user-detail__heading': '詳細資料',

  'chatroom__user-detail__tag__heading': '標籤',
  'chatroom__user-detail__tag-modal__selected-tags__heading': '已選擇的標籤',
  'chatroom__user-detail__tag-modal__selected-tags__empty': '沒有已選擇的標籤',
  'chatroom__user-detail__tag-modal__selectable-tags__heading': '可選擇的標籤',
  'chatroom__user-detail__tag-modal__selectable-tags__empty':
    '沒有可選擇的標籤',

  /* LINE General ------------------------------------------------- */
  'line__general__send-method--broadcast': '廣播訊息',
  'line__general__send-method--multicast': '依標籤傳送',

  'line__general__send-time--immediately': '立即傳送',
  'line__general__send-time--reservation': '預約傳送',

  'line__general__message-type--text': '文字訊息',
  'line__general__message-type--flex': 'Flex message',

  'line__general__field-name--estimated-sending-time': '預約時間',
  'line__general__field-name--send-method': '傳送方式',
  'line__general__field-name--title': '標題 (內部使用)',
  'line__general__field-name--message-type': '訊息類型',
  'line__general__field-name--create-user-name': '建立者',
  'line__general__field-name--created-at': '建立於',

  /* LINE Template ------------------------------------------------ */
  line__template__heading: '單一訊息模板',
  'line__template__button--create': '建立模板',
  line__template__filter__search__label: '標題 (內部使用)',
  line__template__filter__search__placeholder: '搜尋標題 (最長 20 字)',

  'line__template__table__confirm-modal--delete': '刪除該模板?',

  'line__template__form__heading--create': '建立模板',
  'line__template__form__heading--edit': '編輯模板',
  'line__template__form__submit-button--create': '建立',
  'line__template__form__submit-button--edit': '編輯',
  'line__template__form__error-message__title--empty': '標題不可為空',

  'line__template__toast--success__create-template': '成功建立模板',
  'line__template__toast--success__edit-template': '成功編輯模板',
  'line__template__toast--success__delete-template': '成功刪除模板',

  /* LINE Send message -------------------------------------------- */
  'line__send-message__title__description':
    '訊息組為 line 的計價單位，一則訊息組由最多 5 則單一訊息組成',
  'line__send-message__title--create': '建立訊息',
  'line__send-message__title--edit': '編輯訊息',

  'line__send-message__form__label__template-selector': '選擇模板',
  'line__send-message__form__submit-button-text--create': '建立訊息組',
  'line__send-message__form__submit-button-text--edit': '編輯訊息組',
  'line__send-message__form__add-message-button-text': '新增單一訊息',

  'line__send-message__form__error-messages--purpose': '請輸入標題',
  'line__send-message__form__error-messages--date': '請選擇傳送日期',
  'line__send-message__form__error-messages--text__text': '請輸入訊息',
  'line__send-message__form__error-messages--flex-message__alt-text':
    '請輸入 Alt text',
  'line__send-message__form__error-messages--flex-message__contents':
    '請輸入有效的 flex message',

  'line__send-message__dialog--error__get-message': '找無該筆訊息',

  'line__send-message__toast--success__create-message': '成功建立訊息',
  'line__send-message__toast--success__edit-message': '成功編輯訊息',

  /* LINE Messages ------------------------------------------------ */
  line__messages__heading: '訊息列表',
  'line__messages__filter--search__label': '標題 (內部使用)',
  'line__messages__filter--search__placeholder': '搜尋標題 (最長 20 字)',

  'line__messages__filter--send-method__label': '傳送方式',
  'line__messages__send-method-selector--all': '全部',

  'line__messages__table__header--approving-status': '審核狀態',
  'line__messages__table__header--sent-at': '發送時間',

  'line__messages__table__estimated-sending-time--send-immediately': '立即傳送',
  'line__messages__table__approving-status--wait': '等待中',
  'line__messages__table__approving-status--agree': '同意',
  'line__messages__table__approving-status--reject': '拒絕',
  'line__messages__table__sending-status--cancel': '取消發送',
  'line__messages__table__sending-status--sent': '已發送',
  'line__messages__table__sending-status--wait': '等待中',

  /* LINE Message detail modal content ---------------------------- */
  'line__message-detail__edit-message-button': '編輯訊息',
  'line__message-detail__form--confirm__title': '審核訊息',
  'line__message-detail__form--confirm__placeholder': '回饋訊息 (最多 200 字)',
  'line__message-detail__form--confirm__dialog--agree__message':
    '同意發送訊息?',
  'line__message-detail__form--confirm__dialog--reject__message':
    '拒絕發送訊息?',
  'line__message-detail__form--confirm__button--agree': '同意發送',
  'line__message-detail__form--confirm__button--reject': '拒絕發送',

  'line__message-detail__form--cancel__title': '取消發送訊息',
  'line__message-detail__form--cancel__placeholder': '回饋訊息 (最多 200 字)',
  'line__message-detail__form--cancel__dialog__message': '取消發送訊息?',
  'line__message-detail__form--cancel__button': '取消發送',

  'line__message-detail__table__header--approving-status': '審核狀態',
  'line__message-detail__table__header--sent-at': '發送時間',
  'line__message-detail__table__header--confirmed-at': '審核時間',
  'line__message-detail__table__header--confirmed-user-name': '審核人員',
  'line__message-detail__table__header--confirmed-comment': '審核訊息',

  'line__message-detail__table__content__estimated-sending-time--immediately':
    '立即傳送',

  'line__message-detail__table__messages__label': '訊息',

  'line__message-detail__toast--success__cancel-send': '已取消發送訊息',
  'line__message-detail__toast--success__confirm-send': '已審核訊息',

  /* LINE Messages schedule --------------------------------------- */
  'line__message-schedule__calendar__button-text--today': '今日',
  'line__message-schedule__calendar__button-text--month': '月',
  'line__message-schedule__calendar__button-text--week': '周',
  'line__message-schedule__calendar__button-text--day': '日',

  /* Push Notification General ------------------------------------ */
  'push-notification__general__field-name__title': '標題',
  'push-notification__general__field-name__body': '內容',

  /* Push Notification Push --------------------------------------- */
  'push-notification__push__heading': '推播通知',
  'push-notification__push__from__error-messages__title--no-empty':
    '標題不可為空',
  'push-notification__push__from__error-messages__body--no-empty':
    '內容不可為空',

  'push-notification__push__toast--success__push-notification': '成功推播通知',

  /* Tag General -------------------------------------------------- */
  'tag__general__field-name__tag-type': '標籤類型',
  'tag__general__field-name__tag-name': '標籤名稱',
  'tag__general__field-name__priority': '優先級',
  'tag__general__field-name__description': '描述',
  'tag__general__field-name__quantity-tagged': '已標記數量',

  /* Tag table ---------------------------------------------------- */
  tag__table__heading: '標籤',
  'tag__table__button--create': '新增標籤',
  tag__table__filter__search__label: '標籤 / 描述 / 建立者',
  tag__table__filter__search__placeholder: '搜尋標籤 (最長 20 字)',

  /* Tag form ----------------------------------------------------- */
  'tag__form__heading--create': '新增標籤',
  'tag__form__heading--edit': '編輯標籤',
  'tag__form__submit-button--create': '新增標籤',
  'tag__form__submit-button--edit': '編輯標籤',

  'tag__confirm-modal--delete-tag': '刪除該標籤?',

  'tag__toast--success__create-tag': '成功新增標籤',
  'tag__toast--success__edit-tag': '成功編輯標籤',
  'tag__toast--success__delete-tag': '成功刪除標籤',
};
