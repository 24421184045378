/* React */
import { useMemo } from 'react';
/* Light Box */
import YetLightBox from 'yet-another-react-lightbox';
import { Thumbnails, Video, Zoom } from 'yet-another-react-lightbox/plugins';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

/* Hooks */
import useStoreLightBox from '../../../../features/chatroom/hooks/useStoreLightBox';
/* Models */
import type { TMessageItem } from '../../../../models/chatroom/messages';
/* Utils */
import { messagesToLightBoxSlides } from '../../../../utils/messages-to-light-box-slides';

/* //////////////////////////////////////////////////////////////// */
type Props = {
  messages: TMessageItem[];
};

export default function LightBox({ messages }: Props) {
  const {
    openLightBox: openLightBoxCtx,
    curOpenLightBoxSrc: curOpenLightBoxSrcCtx,
    onCloseLightBox: onCloseLightBoxCtx,
  } = useStoreLightBox();

  const slides = useMemo(() => messagesToLightBoxSlides(messages), [messages]);

  const index = slides.findIndex((slide) =>
    slide.type === 'video'
      ? slide.sources.at(0)!.src === curOpenLightBoxSrcCtx
      : slide.src === curOpenLightBoxSrcCtx
  );

  /* JSX ---------------------------------------------------------- */
  if (slides.length === 0) return null;

  return (
    <YetLightBox
      open={openLightBoxCtx}
      close={onCloseLightBoxCtx}
      plugins={[Thumbnails, Video, Zoom]}
      index={index === -1 ? 0 : index}
      slides={slides}
      animation={{ swipe: 300 }}
    />
  );
}
