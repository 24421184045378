/* React */
import { useState, useEffect, MouseEvent } from 'react';

/* Router */
import { useNavigate } from 'react-router-dom';

/* Components */
import NavbarLanguageDropdown from '../../../../components/layouts/Navbar/NavbarLanguageDropdown';

/* Hooks */
import useLogout from '../../../../hooks/useLogout';

/* MUI */
import { Paper, Button, IconButton, Menu, MenuItem, ListItemIcon } from '@mui/material';
import { ChevronLeft, MenuOpen } from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout'; // logout
import styled from '@emotion/styled';

/* i18n */
import { useTranslation } from 'react-i18next';

/* Styled Components ---------------------------------------------- */
const StyledMessagesHeader = styled('div')`
  z-index: 1;
  display: flex;
  justify-content: space-between;
  height: ${(props) => props.theme.header.height};
  padding: 0.75rem;
  border-bottom: none;
  box-shadow: ${(props) => props.theme.header.shadow};
  background-color: ${(props) => props.theme.palette.background.paper};

  ${(props) => props.theme.breakpoints.up('sm')} {
    border-bottom: ${(props) => props.theme.chatroom.border};
    box-shadow: none;
  }
`;
const StyledGoBackButton = styled(IconButton)`
  display: none;
  ${(props) => props.theme.breakpoints.down('xs')} {
    display: inline-flex;
  }
`;

/* //////////////////////////////////////////////////////////////// */
type Props = {
  onToggleShowUserDetail: () => void;
};

export default function MessagesHeader({ onToggleShowUserDetail }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    setAnchorEl(null);
  };

  const handleLanguageAndClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, lang: string) => {
    event?.preventDefault();
    window.localStorage.setItem('locale', lang);
    setAnchorEl(null);
  };

  const { t } = useTranslation();

  const logout = useLogout;


  /* JSX ---------------------------------------------------------- */
  return (
    <StyledMessagesHeader>
      <div>
        <NavbarLanguageDropdown></NavbarLanguageDropdown>
      </div>
      <div>
        <IconButton title="Logout" onClick={(e: MouseEvent) => logout(e)}>
          <LogoutIcon />
        </IconButton>
      </div>
    </StyledMessagesHeader>
  );
}
