import z from 'zod';

/* Server */
export class HttpError extends Error {
  status: string;

  constructor(
    public statusCode: number,
    public message: string
  ) {
    super(message);

    this.status = String(statusCode).startsWith('4') ? 'fail' : 'error';
  }
}

/* Client */
export const httpErrorSchema = z.object({
  error: z.string().optional(),
  status: z.string().optional(),
  statusCode: z.number(),
  message: z.string(),
});

export type THttpError = z.infer<typeof httpErrorSchema>;
